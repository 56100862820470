* {
    box-sizing: border-box;
}

.modal-backdrop
{
    opacity:0.1 !important;
}



/*
  #Media Queries

  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
  @media (min-width: 1281px)

  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
  @media (min-width: 1025px) and (max-width: 1280px)

  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px)
  
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)

  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
  @media (min-width: 481px) and (max-width: 767px)

  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
  @media (min-width: 320px) and (max-width: 480px)
*/

h1 {
  font-family: 'Kanit', sans-serif;
}

h2 {
  font-family: 'Kanit', sans-serif;
}

h3 {
  font-family: 'Kanit', sans-serif;
}

h4 {
  font-family: 'Kanit', sans-serif;
}

p {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}

@media (min-width: 1281px) {

    .containercenter {
        
        display: flex;
        width: 50%;
        max-width: 50%;
        margin: 0 auto;
        justify-content: center;
       
    }

    .bordersmalldisplay {
      visibility: hidden;
    }
    

    .statusheaderfontsize {
      font-size: 25px;
    }

    .displaysampleposition {
      margin-top: 0px;
    }

    .contentimage {
        width: 100%;
        height: auto;
    }

    .borderwriter {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }
  
}
  
  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
@media (min-width: 1025px) and (max-width: 1280px) {
  
    .containercenter {
        
        display: flex;
        width: 50%;
        max-width: 50%;
        margin: 0 auto;
        justify-content: center;
       
    }

    .bordersmalldisplay {
      visibility: hidden;
    }

    .statusheaderfontsize {
      font-size: 25px;
    }

    .displaysampleposition {
      margin-top: 0px;
    }

    .contentimage {
        width: 100%;
        height: auto;
    }

    .borderwriter {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }
  
}
  
  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) {
  
    .containercenter {
        
        display: flex;
        width: 83%;
        max-width: 83%;
        margin: 0 auto;
        justify-content: center;
       
    }

    .bordersmalldisplay {
      visibility: hidden;
    }

    .statusheaderfontsize {
      font-size: 25px;
    }

    .displaysampleposition {
      margin-top: 0px;
    }

    .contentimage {
        width: 100%;
        height: auto;
    }

    .borderwriter {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }


  
}
  
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
    .containercenter {
        
        display: flex;
        width: 83%;
        max-width: 83%;
        margin: 0 auto;
        justify-content: center;
       
    }

    .bordersmalldisplay {
      visibility: hidden;
    }

    .statusheaderfontsize {
      font-size: 20px;
    }

    .displaysampleposition {
      margin-top: 0px;
    }

    .contentimage {
        width: 100%;
        height: auto;
    }

    .borderwriter {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }

  
  
}
  
  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
@media (min-width: 481px) and (max-width: 767px) {
  
    .containercenter {
        
        display: flex;
        width: 95%;
        max-width: 95%;
        margin: 0 auto;
        justify-content: center;
       
    }

    .bordersmalldisplay {
      border: 1px solid #ddd;
      margin-top: 40px;
      visibility: visible;
    }

    .statusheaderfontsize {
      font-size: 16px;
    }

    .displaysampleposition {
      /* border-top: 1px solid #ddd; */
      margin-top: 50px;
    }

    .contentimage {
        width: 100%;
        height: auto;
    }

    .borderwriter {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }

  
}
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
@media (min-width: 320px) and (max-width: 480px) {
  
    .containercenter {
        
        display: flex;
        width: 95%;
        max-width: 95%;
        margin: 0 auto;
        justify-content: center;
       
    }

    .bordersmalldisplay {
      border: 1px solid #ddd;
      margin-top: 40px;
      visibility: visible;
    }

    .statusheaderfontsize {
      font-size: 16px;
    }

    .displaysampleposition {
      /* border-top: 1px solid #ddd; */
      margin-top: 50px;
    }

    .contentimage {
        width: 100%;
        height: auto;
    }

    .borderwriter {
        border: 1px solid #ddd;
        border-radius: 5px;
        padding: 20px;
        margin-left: 3px;
        margin-right: 3px;
    }


}

