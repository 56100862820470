
/* Button */
.nav-active {
    color: gray;
    /* font-weight: bold; */
}

.nav-nonaction {
    color: whitesmoke;
    /* font-weight: bold; */
}

Nav {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}

/* NavDropdown {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
} */
