/* @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); */

* {
    box-sizing: border-box;
}

/*
  #Media Queries

  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
  @media (min-width: 1281px)

  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
  @media (min-width: 1025px) and (max-width: 1280px)

  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px)
  
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)

  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
  @media (min-width: 481px) and (max-width: 767px)

  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
  @media (min-width: 320px) and (max-width: 480px)
*/

h1 {
  font-family: 'Kanit', sans-serif;
}

h2 {
  font-family: 'Kanit', sans-serif;
}

h3 {
  font-family: 'Kanit', sans-serif;
}

h4 {
  font-family: 'Kanit', sans-serif;
}

p {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}

#grad1 {
  background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, orange , yellow, green, cyan, blue, violet); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, orange, yellow, green, cyan, blue, violet); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, orange, yellow, green, cyan, blue, violet); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, orange , yellow, green, cyan, blue, violet); /* Standard syntax (must be last) */
}

.line {
  height: 6px;
  border-radius: 4px;
}

@media (min-width: 1281px) {

    .articlename {
        margin: auto;
        /* margin-left: 10px; */
        /* font: Arial; */
        margin-top: -8px;
        margin-bottom: 5px;
        font-family: 'Kanit', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.3em;
        max-height: 3.2em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

  

  .borderarticle {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px;
    margin-left: 3px;
    margin-right: 3px;
    background-color: #F8F8FF;
   
  }


  .bordernewestarticle {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-left: 3px;
    margin-right: 3px;
    background-color: #F8F8FF;
   
  }
  

  .cardarticledetail {
    height: 140px;

    padding: 10px;
  }

  /* .cardarticledetailimg {
    height: 150px;
    width: 100%;
  } */

  .cardarticledetailimg {

    object-fit: cover; /* Do not scale the image */
    object-position: center; /* Center the image within the element */
    width: 100%;
    /* max-height: 105px; */
    height: 150px;
    /* margin-bottom: 1rem; */

  }

  .cardarticledetailnoimg {
    font-size: 18px;
    width: 100%;
    height: 150px;
    block-size: 150px;
    text-align: center;
    padding-top: 20%;
    background-color: #FFFAFA;
  }

  .cardarticledetailnamedatetime {
    font-size: 14px;
  }

  .cardarticledetaillikebookmark {
    font-size: 14px;
    position: relative;
    bottom: -10px;
    color: gray;
  }
  

  /* .cardarticle {
    background-color: #F8F8FF;
  } */

  
}
  
  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
@media (min-width: 1025px) and (max-width: 1280px) {

    .articlename {
        margin: auto;
        /* margin-left: 10px; */
        /* font: Arial; */
        margin-top: -8px;
        margin-bottom: 5px;
        font-family: 'Kanit', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.3em;
        max-height: 3.2em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }
  
    

    .borderarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .bordernewestarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 20px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .cardarticledetail {
      height: 120px;

      padding: 10px;
    }

    /* .cardarticledetailimg {
      height: 120px;
      width: 100%;
    } */

    .cardarticledetailimg {

      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 100%;
      /* max-height: 105px; */
      height: 120px;
      /* margin-bottom: 1rem; */

    }


    .cardarticledetailnoimg {
      font-size: 16px;
      width: 100%;
      height: 100px;
      block-size: 100px;
      text-align: center;
      padding-top: 20%;
      background-color: #FFFAFA;
    }

    .cardarticledetailnamedatetime {
      font-size: 12px;
    }
  
    .cardarticledetaillikebookmark {
      font-size: 14px;
      position: relative;
      bottom: -5px;
      color: gray;
    }



    /* .cardarticle {
       background-color: #F8F8FF;
    } */
  
}
  
  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) {

    .articlename {
        margin: auto;
        /* margin-left: 10px; */
        /* font: Arial; */
        margin-top: -6px;
        margin-bottom: 5px;
        font-family: 'Kanit', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3em;
        max-height: 3.2em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .borderarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
   
    }

    .bordernewestarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 15px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .cardarticledetail {
      height: 110px;

      padding: 5px;
    }

    /* .cardarticledetailimg {
      height: 110px;
      width: 100%;
    } */

    .cardarticledetailimg {

      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 100%;
      /* max-height: 105px; */
      height: 110px;
      /* margin-bottom: 1rem; */

    }


    .cardarticledetailnoimg {
      font-size: 16px;
      width: 100%;
      height: 110px;
      block-size: 110px;
      text-align: center;
      padding-top: 20%;
      background-color: #FFFAFA;
    }

    .cardarticledetailnamedatetime {
      font-size: 12px;
    }
  
    .cardarticledetaillikebookmark {
      font-size: 12px;
      position: relative;
      bottom: -2px;
      color: gray;
    }


  
    /* .cardarticle {
       background-color: #FFF5EE;
    } */
  
}
  
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    .articlename {
        margin: auto;
        /* margin-left: 10px; */
        /* font: Arial; */
        margin-top: -6px;
        margin-bottom: 2px;
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2em;
        max-height: 3.2em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .borderarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .bordernewestarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 15px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .cardarticledetail {
      height: 100px;

      padding: 5px;
    }

    /* .cardarticledetailimg {
      height: 105px;
      width: 100%;
    } */

    .cardarticledetailimg {

      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 100%;
      /* max-height: 105px; */
      height: 105px;
      /* margin-bottom: 1rem; */

    }

    .cardarticledetailnoimg {
      font-size: 14px;
      width: 100%;
      height: 100px;
      block-size: 100px;
      text-align: center;
      padding-top: 20%;
      background-color: #FFFAFA;
    }

    .cardarticledetailnamedatetime {
      font-size: 12px;
    }
  
    .cardarticledetaillikebookmark {
      font-size: 12px;
      position: relative;
      bottom: -2px;
      color: gray;
    }
      
    
    /* .cardarticle {
        background-color: #FFF5EE;
     } */
  
}
  
  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
@media (min-width: 481px) and (max-width: 767px) {

    .articlename {
        margin: auto;
        /* margin-left: 10px; */
        /* font: Arial; */
        margin-top: -6px;
        margin-bottom: 2px;
        font-family: 'Kanit', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.2em;
        max-height: 3.2em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        
    }

    .borderarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
      
    }

    .bordernewestarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 15px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .cardarticledetail {
      height: 100px;

      padding: 5px;
    }

    /* .cardarticledetailimg {
      height: 105px;
      width: 100%;
    } */

    .cardarticledetailimg {

      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 100%;
      /* max-height: 105px; */
      height: 105px;
      /* margin-bottom: 1rem; */

    }

    .cardarticledetailnoimg {
      font-size: 14px;
      width: 100%;
      height: 100px;
      block-size: 100px;
      text-align: center;
      padding-top: 20%;
      background-color: #FFFAFA;
    }

    .cardarticledetailnamedatetime {
      font-size: 12px;
    }
  
    .cardarticledetaillikebookmark {
      font-size: 12px;
      position: relative;
      bottom: -3px;
      color: gray;
    }
  
    
    /* .cardarticle {
        background-color: #FFF5EE;
     } */
  
}
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
@media (min-width: 320px) and (max-width: 480px) {

    .articlename {
        margin: auto;
        margin-top: -5px;
        /* margin-left: 10px; */
        /* font: Arial; */
        font-family: 'Kanit', sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.3em;
        max-height: 3.2em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;


    }

    .borderarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 0px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
      
    }

    .bordernewestarticle {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 10px;
      margin-left: 3px;
      margin-right: 3px;
      background-color: #F8F8FF;
     
    }

    .cardarticledetail {
        height: 100px;

        padding: 5px;
    }

    /* .cardarticledetailimg {
      height: 105px;
      width: 100%;
    } */

    .cardarticledetailimg {

      object-fit: cover; /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 100%;
      /* max-height: 105px; */
      height: 105px;
      /* margin-bottom: 1rem; */

    }

    .cardarticledetailnoimg {
      font-size: 14px;
      width: 100%;
      height: 100px;
      block-size: 100px;
      text-align: center;
      padding-top: 25%;
      background-color: #FFFAFA;
    }

    .cardarticledetailnamedatetime {
      font-size: 10px;
    }

    .cardarticledetaillikebookmark {
      font-size: 12px;
      position: relative;
      bottom: -4px;
      color: gray;
    }
  
    
    /* .cardarticle {
        background-color: #FFF5EE;
     } */

}