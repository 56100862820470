/* @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); */

* {
    box-sizing: border-box;
}


@media (min-width: 1281px) {

    .image-cropper {
        object-fit: cover; 
        /* Do not scale the image */
        object-position: center; /* Center the image within the element */
        width: 250px;
        /* max-height: 105px; */
        height: 250px;
        /* margin-bottom: 1rem; */
        border-radius: 50%;
  
    }

  
}
  
  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
@media (min-width: 1025px) and (max-width: 1280px) {
  
    .image-cropper {
        object-fit: cover; 
        /* Do not scale the image */
        object-position: center; /* Center the image within the element */
        width: 250px;
        /* max-height: 105px; */
        height: 250px;
        /* margin-bottom: 1rem; */
        border-radius: 50%;
  
    }
  
}
  
  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) {
  
    .image-cropper {
        object-fit: cover; 
        /* Do not scale the image */
        object-position: center; /* Center the image within the element */
        width: 250px;
        /* max-height: 105px; */
        height: 250px;
        /* margin-bottom: 1rem; */
        border-radius: 50%;
  
    }
  
}
  
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
    .image-cropper {
        object-fit: cover; 
        /* Do not scale the image */
        object-position: center; /* Center the image within the element */
        width: 250px;
        /* max-height: 105px; */
        height: 250px;
        /* margin-bottom: 1rem; */
        border-radius: 50%;
  
    }
  
}
  
  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
@media (min-width: 481px) and (max-width: 767px) {
  
    .image-cropper {
        object-fit: cover; 
        /* Do not scale the image */
        object-position: center; /* Center the image within the element */
        width: 200px;
        /* max-height: 105px; */
        height: 200px;
        /* margin-bottom: 1rem; */
        border-radius: 50%;
  
    }
  
}
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
@media (min-width: 320px) and (max-width: 480px) {

    .image-cropper {
      object-fit: cover; 
      /* Do not scale the image */
      object-position: center; /* Center the image within the element */
      width: 200px;
      /* max-height: 105px; */
      height: 200px;
      /* margin-bottom: 1rem; */
      border-radius: 50%;

    }
  
    /* .image-cropper {
        width: 100px;
        height: 100px;
        position: relative;
        overflow: hidden;
        border-radius: 50%;
      }
      
      .imgprofile {
        display: inline;
        margin: 0 auto;
        height: 100%;
        width: 100px;
    
      } */


}