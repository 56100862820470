/* @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); */

* {
    box-sizing: border-box;
}

/*
  #Media Queries

  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
  @media (min-width: 1281px)

  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
  @media (min-width: 1025px) and (max-width: 1280px)

  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px)
  
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)

  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
  @media (min-width: 481px) and (max-width: 767px)

  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
  @media (min-width: 320px) and (max-width: 480px)
*/

h1 {
  font-family: 'Kanit', sans-serif;
}

h2 {
  font-family: 'Kanit', sans-serif;
}

h3 {
  font-family: 'Kanit', sans-serif;
}

h4 {
  font-family: 'Kanit', sans-serif;
}

h5 {
  font-family: 'Kanit', sans-serif;
}

p {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}


/* SLIDE CARD */
div.scroll-container {
  /* background-color: #333; */
  overflow: auto;
  white-space: nowrap;
  /* padding: 10px; */
}

@media (min-width: 1281px) {

  /* .productname {
    margin-bottom: 0;
    font-size: 17px;
  } */

  .addcourseicon {
    font-size: 100px;
  }
  
  .coursename {
    margin: auto;
    /* margin-top: -14px; */
    /* margin-left: 10px; */
    /* font: Arial; */
    font-family: 'Kanit', sans-serif;
    font-size: 16px;

    /* line-height: 1em; */
    max-height: 1.5em;
    overflow: hidden;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    /* -webkit-box-orient: vertical; */
    text-overflow: ellipsis;
    
}

  .coursebageanddiscount {
    font-size: 14px;
    position: relative;
    /* top: 5px; */
    bottom: -20px;
   
  }

  /* h5 {
    font-size: 16;
  } */

  .courseprice {
    font-size: 16px;
    font-weight: bold;
  }

  
}
  
  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
@media (min-width: 1025px) and (max-width: 1280px) {
  
    /* .productname {
        margin-bottom: 0;
        font-size: 17px;
    } */

    .addcourseicon {
        font-size: 80px;
    }

    .coursename {
        margin: auto;
        /* margin-top: -14px; */
        /* margin-left: 10px; */
        /* font: Arial; */
        font-family: 'Kanit', sans-serif;
        font-size: 14px;

        /* line-height: 1em; */
        max-height: 1.5em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .coursebageanddiscount {
      font-size: 12px;
      position: relative;
      /* top: 5px; */
      bottom: -20px;
   
    }

    /* h5 {
      font-size: 14;
    } */

    .courseprice {
      font-size: 14px;
      font-weight: bold;
    }
    
  
  
}
  
  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) {
  
    /* .productname {
        margin-bottom: 0;
        font-size: 16px;
    } */

    .addcourseicon {
        font-size: 60px;
    }

    .coursename {
        margin: auto;
        /* margin-top: -14px; */
        /* margin-left: 10px; */
        /* font: Arial; */
        font-family: 'Kanit', sans-serif;
        font-size: 12px;
   
        /* line-height: 1em; */
        max-height: 1.5em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    .coursebageanddiscount {
      font-size: 12px;
      position: relative;
      /* top: 5px; */
      bottom: -20px;
   
    }

    /* h5 {
      font-size: 12;
    } */

    .courseprice {
      font-size: 12px;
      font-weight: bold;
    }
  
  
}
  
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
    /* .productname {
        margin-bottom: 0;
        font-size: 16px;
    } */

    .addcourseicon {
        font-size: 60px;
    }

    .coursename {
        margin: auto;
        /* margin-top: -14px; */
        /* margin-left: 10px; */
        /* font: Arial; */
        font-family: 'Kanit', sans-serif;
        font-size: 10px;

        /* line-height: 1em; */
        max-height: 1.5em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    /* .productdiscount {
        margin-bottom: 0;
    } */

    .coursebageanddiscount {
      font-size: 12px;
      position: relative;
      /* top: 5px; */
      bottom: -20px;
      
    }

    /* h5 {
      font-size: 10;
    } */

    .courseprice {
      font-size: 15px;
      font-weight: bold;
    }
  
  
}
  
  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
@media (min-width: 481px) and (max-width: 767px) {
  
    /* .productname {
        margin-bottom: 0;
        font-size: 15px;
    } */

    .addcourseicon {
        font-size: 70px;
    }

    .coursename {
        margin: auto;
        /* margin-top: -14px; */
        /* margin-left: 10px; */
        /* font: Arial; */
        font-family: 'Kanit', sans-serif;
        font-size: 10px;

        /* line-height: 1em; */
        max-height: 1.5em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    /* .productdiscount {
        margin-bottom: 0.5;
    } */

    .coursebageanddiscount {
      font-size: 12px;
      position: relative;
      /* top: 5px; */
      bottom: -20px;
  
    }

    /* h5 {
      font-size: 10;
    } */

    .courseprice {
      font-size: 15px;
      font-weight: bold;
    }
  
  
}
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
@media (min-width: 320px) and (max-width: 480px) {
  
    /* .productname {
        margin-bottom: 0;
        font-size: 14px;
    } */

    .addcourseicon {
        font-size: 70px;
    }

    .coursename {
        margin: auto;
        /* margin-top: -14px; */
        /* margin-left: 10px; */
        /* font: Arial; */
        font-family: 'Kanit', sans-serif;
        font-size: 10px;

        /* line-height: 1em; */
        max-height: 1.5em;
        overflow: hidden;
        display: block;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
    }

    /* .productdiscount {
        margin-bottom: 0.5;
    } */

    .coursebageanddiscount {
      font-size: 12px;
      position: relative;
      bottom: -20px;
      /* top: 5px; */
  
    }

    /* h5 {
      font-size: 10;
    } */

    .courseprice {
      font-size: 15px;
      font-weight: bold;
    }
  


}