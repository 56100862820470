/* @import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap'); */

* {
    box-sizing: border-box;
}

/*
  #Media Queries

  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
  @media (min-width: 1281px)

  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
  @media (min-width: 1025px) and (max-width: 1280px)

  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px)
  
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)

  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
  @media (min-width: 481px) and (max-width: 767px)

  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
  @media (min-width: 320px) and (max-width: 480px)
*/

h1 {
  font-family: 'Kanit', sans-serif;
}

h2 {
  font-family: 'Kanit', sans-serif;
}

h3 {
  font-family: 'Kanit', sans-serif;
}

h4 {
  font-family: 'Kanit', sans-serif;
}

p {
  font-family: 'Kanit', sans-serif;
  font-weight: 300;
}

@media (min-width: 1281px) {

  .courseimage {
    width: 100%;
    height: auto;
  }

  .coursenamecolright {
    margin-top: 10px;
  }

  .rightbarcol {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    height: 780px;

    position: sticky;
    top: 30px;
  }

  .responsivedetail {
    display: block;
    width: 800px;
  }

  .borderwhattolearn {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    margin-left: 3px;
    margin-right: 3px;
  }

  
}
  
  /*
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */
  
@media (min-width: 1025px) and (max-width: 1280px) {
  
    .courseimage {
        width: 100%;
        height: auto;
    }

    .coursenamecolright {
      margin-top: 10px;
    }

    .rightbarcol {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 20px;
      height: 780px;

      position: sticky;
      top: 30px;
    }

    .responsivedetail {
      display: block;
      width: 800px;
    }

    .borderwhattolearn {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 20px;
      margin-left: 3px;
      margin-right: 3px;

    }
  
}
  
  /*
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) {
  
    .courseimage {
        width: 80%;
        height: auto;
    }

    .coursenamecolright {
      margin-top: 10px;
    }

    .rightbarcol {
      border: 2px solid #ddd;
      border-radius: 5px;
      padding: 20px;

      position: sticky;
      top: 30px;
    }

    .responsivedetail {
      display: block;
      width: 400px;
    }
  
}
  
  /*
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */
  
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
      
    .courseimage {
        width: 80%;
        height: auto;
    }

    .coursenamecolright {
      margin-top: 10px;
    }

    .rightbarcol {
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 20px;

      position: sticky;
      top: 30px;
    }

    .responsivedetail {
      display: block;
      width: 300px;
    }
  
}
  
  /*
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */
  
@media (min-width: 481px) and (max-width: 767px) {
  
    .courseimage {
        width: 80%;
        height: auto;
    }

    .coursenamecolright {
      margin-top: 10px;
    }

    .rightbarcol {
      border: 0px solid #ddd;
      border-radius: 5px;
      padding: 20px;
    }

    .responsivedetail {
      display: none;
    }
  
}
  
  /*
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */
  
@media (min-width: 320px) and (max-width: 480px) {
  
    .courseimage {
        width: 100%;
        height: auto;
    }

    .coursenamecolright {
      margin-top: 10px;
    }

    .rightbarcol {
      border: 0px solid #ddd;
      border-radius: 5px;
      padding: 20px;
    }

    .responsivedetail {
      display: none;
    }

}